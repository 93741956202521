<template>
	<Modal v-if="showDiscountModal" @close="onCloseDiscountModal" :title="$t('popupvoucher.selectvoucher')">
		<form class="p-5" @submit.stop.prevent="submit">
			<div class="mb-5 rounded-xl bg-light-blue p-4">
				<!-- <label>優惠碼: </label> -->
				<input v-model="code" :placeholder="$t('popupvoucher.voucherCode')" class="outline-none2 w-full bg-transparent placeholder-muted" :disabled="isLoading" required />
			</div>
			<Button type="submit" :disabled="isLoading">{{ $t('popupvoucher.addvoucher') }}</Button>
		</form>
	</Modal>
	<div v-else>
		<Toolbar @clickLeft="isHistory ? toggleHistory() : null" @clickRight="showDiscountModal = !showDiscountModal">
			<template v-slot:start>
				<Icon v-if="isHistory" class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ isHistory ? $t('discounts.exipiredDateDiscounts') : $t('discounts.voucherDiscounts') }}</p>
			<template v-slot:end>
				<p v-if="!isHistory">{{ $t('discounts.addDiscounts') }}</p>
			</template>
		</Toolbar>

		<div class="p-5">
			<!-- <div class="flex justify-end text-muted pb-5">{{$t('discounts.tNcDiscounts')}}</div> -->
			<!-- 優惠列表 -->
			<div v-for="discount in discounts" :key="discount.id" class="mb-5 rounded-xl bg-light-grey p-2" :class="isHistory ? 'bg-gray-300 opacity-60' : ''" @click="setCurrentDiscount(discount)">
				<div class="grid h-full w-full grid-cols-12">
					<div class="col-span-3 flex items-center justify-center text-primary">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
							<path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
						</svg>
					</div>
					<div class="col-span-6 flex flex-col justify-center">
						<p class="text-lg font-bold text-primary">{{ discount.name[currentLang] }}</p>
						<p class="text-xs text-muted">{{ $t('discounts.discountExipiresDate') }}{{ moment(discount.endedAt).format('YYYY-MM-DD') }}</p>
					</div>
					<div class="col-span-3 flex items-center justify-center">
						<p v-if="discount.status == 'INACTIVE'" class="text-muted">{{ $t('discountHistory.voucherUsed') }}</p>
						<button v-else class="text-primary">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div v-if="!isHistory" @click="toggleHistory" class="text-md w-full text-center text-primary">
				{{ $t('discounts.exipiredDateDiscounts') }}
			</div>
		</div>
	</div>

	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useCustomerStore } from '@/store/customer'
import { useCatalogStore } from '@/store/catalog'
import { voucherApi } from 'gox-sdk'
import moment from 'moment'

export default {
	setup() {
		const router = useRouter()
		const store = useStore()
		const { config, currentLang } = storeToRefs(useSharedStore())

		const { currentCustomer } = storeToRefs(useCustomerStore())
		const { vouchers, voucher } = storeToRefs(useCatalogStore())

		const code = ref('')

		const discounts = computed(() => {
			if (isHistory.value) return vouchers.value.filter(voucher => voucher.endedAt < Number(moment().format('x')) || voucher.usedCustomerIds.includes(currentCustomer.value.id))
			return vouchers.value.filter(voucher => voucher.endedAt >= Number(moment().format('x')) && voucher.redeemedCustomerIds.includes(currentCustomer.value.id) && !voucher.usedCustomerIds.includes(currentCustomer.value.id))
		})

		const isHistory = ref(false)

		const showDiscountModal = ref(false)
		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		function setCurrentDiscount(discount) {
			if (isHistory.value) return
			voucher.value = discount
			router.push(`/discounts/${discount.id}`)
		}

		function onCloseDiscountModal() {
			// address.value = null
			// addressIndex.value = null
			showDiscountModal.value = false
		}

		async function submit() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await voucherApi.redeemVoucher({
					customerId: currentCustomer.value.id,
					code: code.value.toUpperCase(),
				})
				// await store.dispatch('createDiscount', { code: code.value.toUpperCase() })
				onCloseDiscountModal()
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		function toggleHistory() {
			isHistory.value = !isHistory.value
		}

		return {
			moment,
			currentLang,
			config,
			vouchers,
			code,
			discounts,
			isHistory,
			error,
			isLoading,
			submit,
			showDiscountModal,
			onCloseDiscountModal,
			toggleHistory,
			setCurrentDiscount,
		}
	},
}
</script>
